<template>
  <div>
    <loading :show="loading">
      <update-article
          ref="article"
          :data="data"
          :ean="ean"
          back-button
          @back="back()"
          @confirm="confirm"
      />
    </loading>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UpdateArticle from "@/views/fotostudio/components/updateArticle.vue";
import DataService from "@/views/fotostudio/services/DataService";

export default {
  components: {UpdateArticle},
  data() {
    return {
      service: new DataService(),
      ean: this.$route.params.ean,
      data: {},
      loading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.service.getPhotographerFields(this.ean).then(response => {
        this.data = response.data.data
        this.loading = false
      }).catch(() => {
        this.$router.replace(`/fotostudio/search/`)
      })
    },
    confirm(data) {
      this.loading = true
      this.service.confirmArticleByPhotographer(this.ean, data).then(response => {
        this.$router.replace(`/fotostudio/search/`).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        })
      }).catch(() => {
        this.loading = false
      })
    },
    back() {
      this.$router.replace(`/fotostudio/search/`)
    }
  }
}
</script>
